import '../../styles/globals.css';
import '../../styles/scss/_app.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from '../_redux/store'
import Layout from '../components/Layouts/Layout';
import { useSSR } from '@nextui-org/react'


function MyApp({ Component, pageProps }) {
  const { isBrowser } = useSSR()
  return (
    isBrowser && (
    <Provider store={store} >
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </Provider>
    )
  )
}




export default MyApp;
