import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  getConfigurationData,
  getHomePageContentData,
  getServiceSegmentsData,
  getUserData,
} from "../../services/user";
import {
  setConfigData,
  setGlobalLoading,
  setSegmentErrMessage,
  setServiceSegments,
  setShowLoggedInPopup,
  setShowRegisterPopup,
  setSiteData,
  userData,
} from "../../_redux/actions";
import FullPageLoader from "../Utils/FullPageLoader";
// import { googleKey } from "../Utils/GoogleKey";

const Header = dynamic(import("../Header/Header"));
const Footer = dynamic(import("../Header/Footer"));
const LoginModal = dynamic(import("../modals/LoginModal"));
const RegisterModal = dynamic(import("../modals/RegisterModal"));

const mapStateToProps = (state) => ({
  isLoggedInPopup: state.user.isLoggedInPopup,
  isRegisterPopup: state.user.isRegisterPopup,
  userData: state.user.userData,
  isLoggedIn: state.user.isLoggedIn,
  siteData: state.user.siteData,
  userLocation: state.user.userLocation,
  serviceSegments: state.user.serviceSegments,
  globalLoading: state.user.globalLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setShowLoggedInPopup: (data) => {
    dispatch(setShowLoggedInPopup(data));
  },
  setShowRegisterPopup: (data) => {
    dispatch(setShowRegisterPopup(data));
  },
  setUserData: (data) => {
    dispatch(userData(data));
  },
  setConfigData: (data) => {
    dispatch(setConfigData(data));
  },
  setSiteData: (data) => {
    dispatch(setSiteData(data));
  },
  setServiceSegments: (data) => {
    dispatch(setServiceSegments(data));
  },
  setSegmentErrMessage: (data) => {
    dispatch(setSegmentErrMessage(data));
  },
  setGlobalLoading: (data) => {
    dispatch(setGlobalLoading(data));
  },
});



function Layout(props) {
  const [config , setConfig] = useState("")
  // const googleKeys = localStorage.getItem('googleKey')

  // console.log("key",config)
  const {
    children,
    isLoggedInPopup,
    isRegisterPopup,
    setShowLoggedInPopup,
    setShowRegisterPopup,
    setUserData,
    isLoggedIn,
    setConfigData,
    setSiteData,
    siteData,
    userLocation,
    setServiceSegments,
    setSegmentErrMessage,
    serviceSegments,
    globalLoading,
    setGlobalLoading,
  } = props;

  const router = useRouter();

  const toastConfig = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    if (isLoggedIn && userLocation.lat && userLocation.lng) {
      const currentLatLong = JSON.parse(localStorage.getItem("currentLatLong"));
      handleGetServiceSegments(currentLatLong);
    } else {
      setServiceSegments([]);
      setGlobalLoading(false);
    }
  }, [isLoggedIn, userLocation]);

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      if (isLoggedIn) {
        // setGlobalLoading(true);
      } else {
        setGlobalLoading(false);
      }
    });
    handleGetConfigData();
    handleGetSiteContentData();
  }, [isLoggedIn, router]);

  // useEffect(() => {
  //   if (serviceSegments.length == 1) {
  //     router.push(`/services/${serviceSegments[0].title}`);
  //   }
  // }, [serviceSegments]);

  async function getUserDataHandler() {
    const params = {
      operating_system: "IOS",
      apk_version: 1.4,
      language_code: "en",
      manufacture: "iPhone",
      device: 2,
      unique_no: "1759B753-E111-4731-B701-47FF45623B8F",
      model: "iPhone",
      package_name: "com.appyourservice.user",
      player_id: "fc72ca21-a128-4c86-9c71-b6046f30a122",
    };

    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });

    try {
      const { response } = await getUserData(formData);
      if (response.status) {
        setUserData(response.data);
      }
    } catch (e) {}
  }

  async function handleGetServiceSegments(location) {
    // setGlobalLoading(true);
    const params = {
      latitude: location?.lat ,
      longitude: location?.lng ,
    };

    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });

    const { response } = await getServiceSegmentsData(formData);

    if (response.status) {
      const segments = response.data.find(
        (item) => item.cell_title === "ALL SERVICES"
      ).cell_contents;
      setServiceSegments(segments);
      setSegmentErrMessage("");
      // if (segments.length === 1) {
      //   router.push(`/services/${segments[0].title}`);
      // }
      setGlobalLoading(false);
    } else {
      setServiceSegments([]);
      setGlobalLoading(false);
      setSegmentErrMessage(response.message);
      // toast.error(response.message, toastConfig);
    }
  }

  async function handleGetConfigData() {
    const params = {
      player_id: "cf86af69-6ef3-41a2-bcbe-a78440bddb43",
      operating_system: "IOS",
      package_name: "com.multiservice.user",
      manufacture: "iPhone",
      device: 2,
      model: "iPhone",
      user_id: 0,
      language_code: "en",
      unique_no: "B26A3344-F742-4442-A321-C741EA1D36B0",
      apk_version: 2.0,
    };

    const formData = new FormData();
    Object.keys(params).forEach((key) => formData.append(key, params[key]));

    const { response } = await getConfigurationData(formData);

    if (response.data) {
      setConfigData(response.data);
      setConfig(response.data?.key_data?.user_android_key)
      
    }
  }
  // console.log("config",config)
  async function handleGetSiteContentData() {
    const { response } = await getHomePageContentData({});
    if (response.status) {
      setSiteData(response.data);
    }
  }

  function openLoginPopup() {
    setShowLoggedInPopup(true);
    setShowRegisterPopup(false);
  }

  function openRegisterPopup() {
    setShowLoggedInPopup(false);
    setShowRegisterPopup(true);
  }

  function closeModal() {
    setShowLoggedInPopup(false);
    setShowRegisterPopup(false);
  }

  return (
    <>
      {siteData && (
        <style
          dangerouslySetInnerHTML={{
            __html: ` :root {
    --bg-primary-color: ${siteData?.app_theme?.bg_color_primary};
    --bg-secondary-color: ${siteData?.app_theme?.bg_color_secondary};
    --label-primary-color: ${siteData?.app_theme?.text_color_primary};
    --label-secondary-color: ${siteData?.app_theme?.text_color_secondary};
}`,
          }}
        />
      )}

      {isLoggedInPopup && (
        <LoginModal
          openRegisterPopup={openRegisterPopup}
          closeModal={closeModal}
        />
      )}

      {isRegisterPopup && (
        <RegisterModal
          openLoginPopup={openLoginPopup}
          closeModal={closeModal}
        />
      )}
      {globalLoading && <FullPageLoader />}

      <Head>
        <title>{siteData?.header?.title}</title>
        <meta name="description" content={siteData?.header?.title}></meta>
        <script
          async
          src={`https://maps.googleapis.com/maps/api/js?key=${config}&libraries=places`}
        ></script>
      </Head>
      <ToastContainer />
      <Header />
      <div className="main-content">{children}</div>
      <Footer />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
